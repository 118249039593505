<template>
    <div>
        
        <detail-header 
            v-scroll="0"
            :title="title"
            :lefttext="lefttext" 
            :returnPath="returnPath"
            @event="change(returnPath)">
        </detail-header>

        <div style="margin-top: 46px;" :key="prograjList.length">
            <!-- <div style="align-items: center;text-align: center;">{{name}}</div> -->
        
        


            <van-tabs :active="active"  :ellipsis=true @click-tab="onClickTap">
         
                <van-tab v-for="item in prograjList" 
                    :title="item.title" :name="item.videopath"
                    :key="item.id">
                    <video-player 
                    :src="item.videopath"
                    :options="playerOptions"
                    :volume="0.6"/>

                </van-tab>
            </van-tabs>

        </div>

    </div>
</template>
<script>
import { onMounted,inject, onUnmounted, reactive, toRefs,ref } from 'vue'
import {useRoute,useRouter} from 'vue-router'
import detailHeader from '@/components/home/notice/DetailHeader.vue'
import axios from 'axios'
import setStorage from '@/store/storage.js'
import api from '@/util/requestApi.js'
export default {
    components:{
        detailHeader
    },
    setup(props) {
        const router = useRouter()// router === this.$router 
        const isShow = inject("taozishow")//注入 
        // const active = ref(0);
        // const videoSrc = ref('https://www.yueshunlian.top/video/a588916f7c19b8d6ca3a4bbcf59c6353.mp4');
        // 视频播放器配置
        let playerOptions = ref({
            // height: 200,
            // width: document.documentElement.clientWidth, //播放器宽度
            playbackRates: [0.7, 1.0, 1.5, 2.0], // 播放速度
            autoplay: false, // 如果true,浏览器准备好时开始回放。
            muted: true, // 默认情况下将会消除任何音频。
            loop: false, // 导致视频一结束就重新开始。
            preload: 'auto', // 建议浏览器在<video>加载元素后是否应该开始下载视频数据。auto浏览器选择最佳行为,立即开始加载视频（如果浏览器支持）
            language: 'zh-CN',
            aspectRatio: '16:9', // 将播放器置于流畅模式，并在计算播放器的动态大小时使用该值。值应该代表一个比例 - 用冒号分隔的两个数字（例如"16:9"或"4:3"）
            fluid: true, // 当true时，Video.js player将拥有流体大小。换句话说，它将按比例缩放以适应其容器。
            notSupportedMessage: '此视频暂无法播放，请稍后再试', // 允许覆盖Video.js无法播放媒体源时显示的默认信息。
            controls: true,
            controlBar: {
                timeDivider: true,
                durationDisplay: true,
                remainingTimeDisplay: false,
                fullscreenToggle: true // 全屏按钮
            }
        })
        const obj = reactive({
            title:"",//标题
            lefttext:"首页",//左边按钮文字 
            name:"",
            videoSrc:"",
            prograjList:[],
            active:1
            
        })

        onMounted(()=>{
            document.querySelector(".van-tabs__content").style="margin-top: 5px"
            
            isShow.value = false
            init()
        })
        onUnmounted(()=>{
            isShow.value = true
        })

        const change = ()=>{
            router.push(`/`)
        }

        const onClickTap = (title)=>{
            obj.name=title.title
            obj.videoSrc = title.name
            console.log(title)
        }

        const demo = ()=>{
            obj.active = 1
        }

        const init = ()=>{
            console.log(8888)

            //发送请求
            axios({
                method: "get",
                url: api.dev+`/citylife/newContent/activity/queryCoverdetailsList`,
                data: {
          
                },
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            })
            .then((res) => {
                
                if(res.data.code==200){
                    setTimeout(function() {
                        console.log(res.data)
                        obj.prograjList = res.data.data
                        obj.active=1
                    }, 500); // 定时时间
                    
            
                }else if(res.data.code==201){
                    showToast(res.data.message);
                }
                
            })
            .catch((err) => {
                console.log(err);
            });
        }

        return {
            ...toRefs(obj),
            change,
            onClickTap,
            init,
            playerOptions,
            demo
        }
    }
    
}
</script>
<style>
.bottom{
    border: 0px solid red;
    height: 350px;
    display: flex;
    justify-content: center;
    align-items: center;
    /* height: calc(100% - 44px); */
}
video {
  max-width: 100%;
  outline: none;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.15);
  border-radius: 8px;
} 
</style>
