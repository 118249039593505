<template>
    <div>
        <detail-header 
          v-scroll="0"
          :title="title"
          :lefttext="lefttext" 
          :righttext="righttext"
          :returnPath="returnPath"
          @register="onClickIndex(returnPath)"
          @event="onClickReturn(returnPath)">
        </detail-header>

        
        <ul style="margin-top: 46px;">
            <router-link to="/login/passwordlogin" custom v-slot="{navigate,isActive}">
                <li @click="navigate">
                <span :class="isActive?'taoziactive':''">密码登录</span>
                </li>
            </router-link>

            <router-link to="/login/smslogin" custom v-slot="{navigate,isActive}">
                <li @click="navigate">
                <span :class="isActive?'taoziactive':''">短信登录</span>
                </li>
            </router-link>
        </ul>
        <!-- 路由容器-->
        <router-view></router-view>

    </div>
</template>
<script>
import { onMounted,inject, onUnmounted, reactive, toRefs } from 'vue'
import {useRoute,useRouter} from 'vue-router'
import detailHeader from '@/components/home/notice/DetailHeader.vue'

export default {
    components:{
        detailHeader
    },
    setup(props) {
        const router = useRouter()// router === this.$router
        const isShow = inject("taozishow")//注入 

        const obj = reactive({
            returnPath:'/',
            title:"登录",//标题
            righttext:"首页",//右边按钮文字
            lefttext:"返回"//左边按钮文字
        })

        onMounted(()=>{
            isShow.value = false
        })
        onUnmounted(()=>{
            isShow.value = true
        })

        const onClickReturn = ()=>{
            router.push(`/my`)
        }

        const onClickIndex = (params)=>{
            router.push(`${params}`)
        }

        return {
            ...toRefs(obj),
            onClickReturn,
            onClickIndex
        }
    }
}
</script>
<style lang="scss" scoped>
ul {
    display: flex;
    height: 3.0625rem;
    line-height: 3.0625rem;
    li {
        flex: 1;
        text-align: center;
    }
}
.taoziactive{
    color: red;
    border-bottom: 2px solid red;
}
</style>