<template>
    <div>
        <detail-header 
          v-scroll="0"
          :title="title"
          :lefttext="lefttext" 
          :righttext="righttext"
          :returnPath="returnPath"
          @register="onClickIndex(returnPath)"
          @event="onClickReturn(returnPath)">
        </detail-header>

        <van-cell-group inset style="margin-top: 46px;">
         <van-field
            v-model="phone"
            center
            clearable
            label="手机号"
            placeholder="请输入手机号码">
        </van-field>
         <van-field
            v-model="username"
            center
            clearable
            label="名称"
            placeholder="请输入用户名称">
        </van-field>
        <van-field
            v-model="sms"
            center
            clearable
            label="验证码"
            placeholder="请输入短信验证码"
        >
            <template #button>
            <van-button :style="isShowButton" size="small" type="primary" @click="start">发送验证码</van-button>
            <van-count-down
                :style="isShowDown"
                ref="countDown"
                millisecond
                :time="60000"
                :auto-start="false"
                format="ss 秒"
                @finish="onFinish"
            />
            </template>
        </van-field>
        <!-- 输入密码 -->
        <van-field  type="password" v-model="password" label="密码" placeholder="请输入密码"/>
        <!-- 输入密码 -->
        <van-field  type="password" v-model="confirmpassword" label="确认密码" placeholder="请输入密码"/>
        </van-cell-group>
        <van-button type="primary" size="large" :disabled="disabledFlag" @click="submit">确定</van-button>

    </div>
</template>
<script>
import { onMounted,inject, onUnmounted, reactive, toRefs,ref } from 'vue'
import {useRoute,useRouter} from 'vue-router'
import detailHeader from '@/components/home/notice/DetailHeader.vue'
import { showToast,setToastDefaultOptions} from 'vant';
import common from '@/util/common'
import axios from 'axios'
import api from '@/util/requestApi.js'
export default {
    components:{
        detailHeader
    },
    setup(props) {
        const router = useRouter()// router === this.$router 
        const isShow = inject("taozishow")//注入 

        const countDown = ref(null);

        const obj = reactive({
            disabledFlag:false,
            returnPath:'/',
            title:"注册",//标题
            righttext:"首页",//右边按钮文字
            lefttext:"返回",//左边按钮文字
            phone:"",
            username:"",
            sms:"",
            password:"",
            confirmpassword:"",
            isShowButton:"",
            isShowDown:"display:none"
        })

        onMounted(()=>{
            isShow.value = false
        })
        onUnmounted(()=>{
            isShow.value = true
        })

        // 点击发送
        const start = () => {
            
            //判断是否为空
            var phone =common.isStringNotEmpty(common.trimAll(obj.phone))
            if(phone){
                obj.isShowDown=""
                obj.isShowButton="display:none"
                countDown.value.start();
                const getPhone = common.trimAll(obj.phone);
                showToast('该功能暂未开放');
                return
                // axios({
                //     method: "post",
                //     url: api.dev+`/citylife/newContent/activity/loginSendSMS/${getPhone}`,
                //     // data: form,
                //     // headers: {
                //     //     'Content-Type': 'application/json;charset=UTF-8'
                //     // }
                // })
                // .then((res) => {
                //     console.log(res)
                //     if(res.data.code==20000){
                //         showToast('短信验证码为:'+res.data.data.code);
                //     }else if(res.data.code==20001){
                //         showToast(res.data.message);
                //     }
                    
                // })
                // .catch((err) => {
                //     console.log(err);
                // });
            }else{
                //提示
                showToast('请输入手机号');
            }
        };

        const submit = ()=>{
            obj.disabledFlag = true;
            //判断是否为空
            var setphone = common.trimAll(obj.phone);
            var setname = common.trimAll(obj.username);
            var setcode = common.trimAll(obj.sms);
            var setpassword = common.trimAll(obj.password);
            var setconfirmpassword = common.trimAll(obj.confirmpassword);

            var phone = common.isStringNotEmpty(setphone)
            if(!phone){
                showToast('请输入手机号');
                return
            }
            var username = common.isStringNotEmpty(setname)
            if(!username){
                showToast('请输入用户名称');
                return
            }
            var sms = common.isStringNotEmpty(setcode)
            if(!sms){
                showToast('请输入验证码');
                return
            }
            var password = common.isStringNotEmpty(setpassword)
            if(!password){
                showToast('请输入密码');
                return
            }
            var confirmpassword = common.isStringNotEmpty(setconfirmpassword)
            if(!confirmpassword){
                showToast('请输入确认密码');
                return
            }
            
            if(setpassword!=setconfirmpassword){
                showToast('两次的密码不一致');
                return
            }

            showToast('该功能暂未开放');
                return
            
            //发送请求
            // axios({
            //     method: "post",
            //     url: api.dev+`/citylife/newContent/activity/insertUser`,
            //     data: {
            //         phonenumber:setphone,
            //         username:setname,
            //         password:setpassword,
            //         confirmpassword:setconfirmpassword,
            //         smscode:setcode
            //     },
            //     headers: {
            //         'Content-Type': 'multipart/form-data'
            //     }
            // })
            // .then((res) => {
            //     obj.disabledFlag = false;
            //     if(res.data.code==20001){
            //         showToast(res.data.message)
            //     }else if(res.data.code==20000){
            //         // duration延迟时间
            //         setToastDefaultOptions({ 
            //             duration: 3000,
            //         });
            //         showToast("注册成功")
            //         obj.phone="";
            //         obj.username="";
            //         obj.sms="";
            //         obj.password="";
            //         obj.confirmpassword="";
            //         setTimeout(function() {
            //             // 需要执行的代码		
            //             //跳转登录页面
            //             router.push(`/my/login`)
            //         }, 3500); // 定时时间
                    
            //     }
            // })
            // .catch((err) => {
            //     console.log(err);
            // });
            
        }

        //结束了促发这个函数
        const onFinish = () =>{
            obj.isShowButton=""
            obj.isShowDown="display:none"
            countDown.value.reset();
        };

        const onClickReturn = ()=>{
            router.push(`/my`)
        }

        const onClickIndex = (params)=>{
            router.push(`${params}`)
        }

        return {
            ...toRefs(obj),
            onClickReturn,
            onClickIndex,
            start,
            submit,
            onFinish,
            countDown
        }
    }
}
</script>