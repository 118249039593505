<template>
    <div v-if="detailInfo">

        <detail-header 
            v-scroll="50"
            :lefttext="lefttext" 
            :title="detailInfo.name"
            :returnPath="returnPath"
            @event="change(returnPath)">
        </detail-header>

        <div :style="{
                backgroundImage:'url('+detailInfo.poster+')'
            }" class="poster">
        </div>

        <div class="content">

            <div>{{ detailInfo.name }}</div>
            <div>
                <div class="detail-text">{{ detailInfo.category }}</div>
                <div class="detail-text">{{ dateFilter(detailInfo.premiereAt)}}</div>
                <div class="detail-text">{{ detailInfo.nation }} | {{ detailInfo.runtime }}分钟</div>
                <div class="detail-text" style="line-height: 15px;" :class="isHidden?'hidden':''">
                    {{ detailInfo.synopsis }}
                </div>
                <!-- <div style="text-align: center;">
                    <i class="iconfont " @click="isHidden=!isHidden" :class="isHidden?'icon-row-full':'icon-fanhuijiantou'"></i>
                </div> -->
            </div>
            <van-back-top />
            <!-- 演职人员 -->
            <div style="margin-top: 10px;">
               <div>演职人员</div>
                <detail-swiper :perview="3.5" name="actors">
                    <detail-swiper-item v-for="(data,index) in detailInfo.actors" :key="index">
                    <div :style="{
                        backgroundImage:'url('+data.avatarAddress+')'
                        }" class="avatar">
                    </div>
                    <div style="font-size: 14px;text-align: center;">{{ data.name }}</div>
                    <div style="font-size: 12px;text-align: center;">{{ data.role }}</div>
                    </detail-swiper-item>
                </detail-swiper>
            </div>

            <!-- 剧照 -->
            <div style="margin-top: 10px;">
                <div>剧照</div>
                <detail-swiper :perview="2" name="photos">
                    <detail-swiper-item v-for="(data,index) in detailInfo.photos" :key="index">
                    <div :style="{
                        backgroundImage:'url('+data+')'
                        }" class="avatar taozi-enter-active" @click="handlePreview(index)">
                    </div>
                    </detail-swiper-item>
                </detail-swiper>
            </div>
            
        </div>

    </div> 
</template>
<script>
import { onMounted,inject, onUnmounted, reactive, toRefs,computed } from 'vue'
import {useRoute,useRouter} from 'vue-router'
import detailHeader from '@/components/home/notice/DetailHeader.vue'
import http from '@/util/http'
// 日期格式化
import moment from 'moment'
import detailSwiper from '@/components/home/carousel/DateilSwiper.vue'
import detailSwiperItem from '@/components/home/carousel/DateilSwiperItem.vue'
import { showImagePreview } from 'vant'
export default {
    // 导入组件 
    components:{
        detailHeader,
        detailSwiper,
        detailSwiperItem
    },
    setup(){
        const route = useRoute() //route === this.$route
        const router = useRouter()// router === this.$router
        const isShow = inject("taozishow")//注入 
        const list = [...Array(50).keys()];
        const obj = reactive({
            lefttext:"返回",//左边按钮文字
            returnPath:'/',
            detailInfo: null,
            isHidden: true
        })
        onMounted(()=>{
            console.log(route.params.id)
            isShow.value = false

            // axios 利用id 发请求到详情接口，获取详情数据，布局页面
            http({
                url: `/gateway?filmId=${route.params.id}&k=7367617`,
                headers: {
                    'X-Host': 'mall.film-ticket.film.info'
                }
            }).then(res => {
                console.log(res)
                obj.detailInfo = res.data.data.film
            })
            
        })
        onUnmounted(()=>{
            isShow.value = true
        })

        const change = (params)=>{
            router.push(`${params}`)
        }

        const dateFilter = computed(()=>{
            return function(date){
                return moment(date * 1000).format('YYYY-MM-DD')
            }
        })
        const handlePreview = (index)=>{
            // 图片预览
            showImagePreview({
                images: obj.detailInfo.photos,
                startPosition: index,
                showIndicators:true,
                closeable: true,
                loop:true
                // transition: 'taozi-enter-active'// 动画
            })
        }

        return {
            ...toRefs(obj),
            change,
            dateFilter,
            handlePreview,
            list
        }
    },
    // 指令
    directives:{
        scroll:{
            mounted(el,binding){
                el.style.display = 'block'
                window.onscroll = () => {
                    if((document.documentElement.scrollTop || document.body.scrollTop) <= binding.value){
                        el.style.display = 'block'
                    }else if((document.documentElement.scrollTop || document.body.scrollTop)>binding.value*2){
                        el.style='display:block'
                    }else{
                        el.style='display:none'
                    }
                }
            },
            // 销毁执行的
            unmounted () {
                window.onscroll = null
            }
        }
    }
}
</script>
<style lang="scss" scoped>
  .poster{
    width: 100%;
    height: 26.25rem;
    background-position: center;
    background-size: cover;
  }
  .content{
    padding: 1.875rem;
    .detail-text{
      color: #797d82;
      font-size:13px;
      margin-top: .25rem;
    }
  }
.hidden{
  overflow: hidden;
  height: 30px;
}
.avatar{
  width: 100%;
  height: 8.5rem;
  background-position: center;
  background-size: cover;
}
</style>