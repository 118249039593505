<template>
    <div> 
        
        <van-nav-bar title="标题" left-text="返回" left-arrow @click-left="onClickLeft"/>
    </div>
</template>
<script>
import { onMounted,inject, onUnmounted, reactive, toRefs } from 'vue'
import {useRoute,useRouter} from 'vue-router'

export default {
    setup(props) {
        const route = useRoute() //route === this.$route
        const router = useRouter()// router === this.$router
        const isShow = inject("taozishow")//注入 

        const obj = reactive({
            id:route.params.id
        })

        onMounted(()=>{
            console.log(route.params.id)
            isShow.value = false
        })
        onUnmounted(()=>{
            isShow.value = true
        })

        const onClickLeft = () => {
            // 返回上一级
            // history.back();
            router.push(`/`)

        }

        return {
            ...toRefs(obj),
            handleClick,
            onClickLeft
        }
    }
}
</script>