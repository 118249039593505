<template>
  <div>

    <van-tabbar route v-model="active" v-show="isShow">
      <van-tabbar-item  v-for="(item,index) in tabbar" @click="tabIndex(index)" :to="(item.routerLink)" :key="item.id"
         replace :icon="item.icon">
        {{item.name}}
      </van-tabbar-item>
    </van-tabbar>
    
    <router-view/>

  </div>
</template>
<script>
import { reactive,toRefs,ref, onMounted,provide } from 'vue'
import {useRoute,useRouter} from 'vue-router'
export default {
  setup() {

    const active = ref(0); //默认选中tab

    const router = useRouter()// router === this.$router
    const route = useRoute()// router === this.$route

    const isShow = ref(true)
    provide("taozishow",isShow)// 供应

    const tabIndex = (index)=> {
      index = Number(index)
      active.value = index;
      // 记录当前tab页
      sessionStorage.setItem('tabIndex',index)
      let val = obj.tabbar[index].routerLink
      router.push(val);
    }

    const clickprogram = (parameter)=>{
      router.push(`${parameter}`);
    }

    onMounted(()=>{

      let fullUrl = window.location.href;
      if(fullUrl=="http://localhost:8080/program"){
        var parameter = "/program"
        clickprogram(parameter)
        return;
      }
      if(fullUrl=="http://localhost:8080/programPlayback"){
        var parameter = "/programPlayback"
        clickprogram(parameter)
        return;
      }
      
      // 如有缓存，刷新页面时还进入该页面
      let index = sessionStorage.getItem('tabIndex')
      if(index){
        tabIndex(index)
      }
    })

    const obj = reactive({
      tabbar:[{
        id:1,
        name:"主页",
        icon:"home-o",
        routerLink:"/"
      },{
        id:2,
        name:"我的",
        icon:"friends-o",
        routerLink:"/my"
      }]
    })

    return { 
      ...toRefs(obj),
      active,
      tabIndex,
      isShow,
      clickprogram
    };
  }
}
</script>
