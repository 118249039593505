import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '@/views/HomeView.vue'
import MyView from '@/views/MyView.vue'
import Detail from '@/views/home/notice/Detail.vue'
import Detail1 from '@/views/home/notice/Detail1.vue'
import NameList from '@/views/home/notice/NameList.vue'
import Disclaimers from '@/views/my/disclaimers/Disclaimers.vue'
import About from '@/views/my/about/About.vue'
import Login from '@/views/my/login/Login.vue'
import Passwordlogin from '@/views/my/login/Passwordlogin.vue'
import Smslogin from '@/views/my/login/Smslogin.vue'
import Register from '@/views/my/register/Register.vue'
import Setting from '@/views/my/setting/Setting.vue'
import ProgramList from '@/views/home/program/ProgramList.vue'
import ProgramPlayback from '@/views/home/program/ProgramPlayback.vue'

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },{
    path: '/home/notice/detail/:id',
    component: Detail
  },{
    path: '/home/notice/detailed/:id',
    component: Detail1
  },{
    path: '/home/notice/detail/nameList/:id',
    component: NameList
  },{
    path: '/program',
    component: ProgramList
  },{
    path: '/programPlayback',
    component: ProgramPlayback
  },{
    path: '/my',
    component: MyView
  },{
    path: '/my/about',
    component: About
  },{
    path: '/my/login',
    component: Login,
    // 二级路由(嵌套路由)  
    children: [
      {
        path: '/login/passwordlogin',
        component: Passwordlogin
      },
      {
        path: '/login/smslogin',
        component: Smslogin
      }, {
        path: '/my/login',
        redirect: '/login/passwordlogin'

      }
    ]
  },{
    path: '/my/register',
    component: Register
  },{
    path: '/my/disclaimers',
    component: Disclaimers
  },{
    path: '/my/setting',
    component: Setting
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
