// 对于数据请求的封装 第二种方案 
import axios from 'axios'
import { showLoadingToast,closeToast} from 'vant'
import api from '@/util/requestApi.js'
const http = axios.create({
  // baseURL: 'https://m.maizuo.com',
  baseURL:api.dev,
  timeout: 10000,
  // headers: {
  //   'X-Client-Info': '{"a":"3000","ch":"1002","v":"5.2.1","e":"1695225403475684807901185"}'
  // }
})
// 在发请求之前拦截--showloading
// 发请求之前要不要加点东西(token,或者。。)
http.interceptors.request.use(function (config) {

  showLoadingToast({
    message: '加载中...',
    forbidClick: true,
  });
  return config
}, function (error) {
  return Promise.reject(error)
})
// 在成功后拦截-- hideloading
http.interceptors.response.use(function (response) {
  closeToast();
  return {
    ...response,
    aaa: 'taozi'
  }
}, function (error) {
  closeToast();
  return Promise.reject(error)
})
export default http

// // 对于数据请求的封装 第一种方案
// import axios from 'axios'
// function httpList () {
//   return axios({
//     url: 'https://m.maizuo.com/gateway?cityId=440300&pageNum=5&pageSize=10&type=1&k=4313750',
//     headers: {
//       'X-Client-Info': '{"a":"3000","ch":"1002","v":"5.2.1","e":"1695225403475684807901185"}',
//       'X-Host': 'mall.film-ticket.film.list'
//     }
//   })
// }
// function httpDetail (params) {
//   return axios({
//     url: `https://m.maizuo.com/gateway?filmId=${params}&k=7367617`,
//     headers: {
//       'X-Client-Info': '{"a":"3000","ch":"1002","v":"5.2.1","e":"1695225403475684807901185","bc":"440300"}',
//       'X-Host': 'mall.film-ticket.film.info'
//     }
//   })
// }
// export default {
//   httpList,
//   httpDetail
// }
