<template>
  <div>

    <detail-header 
          v-scroll="0"
          :title="title"
          :lefttext="lefttext" 
          :righttext="righttext"
          :returnPath="returnPath"
          :leftdisabled="leftdisabled"
          :rightdisabled="rightdisabled"
          @register="onClickRegister(returnPath)"
          @event="onClickLogin(returnPath)">
    </detail-header>

    <div class="uploaderfile">
      <div class="imgfile">
        <van-uploader  class="" accept="*" :v-model="fileList" :after-read="afterRead">
          <img :src="imgur">
        </van-uploader>
        <div class="username">
          {{ text }}
        </div>
      </div>
    </div>
    

    <div class="listmenu">
      <van-cell size="large" :border="true" v-for="data in listData" :key="data"
     :title="data.title" :icon="data.icon" is-link @click="handleClick(data.url)"/>
    </div>
    <!-- <van-cell size="large" :border="true" title="意见反馈" icon="records-o" is-link />
    <van-cell size="large" :border="true" title="免责声明" icon="description-o" is-link />
    <van-cell size="large" :border="true" title="关于我们" icon="location-o" is-link />
    <van-cell size="large" :border="true" title="设置" icon="location-o" is-link /> -->
  </div>
</template>
<script>
import { onMounted,onUnmounted, reactive, toRefs } from 'vue';
import {useRoute,useRouter} from 'vue-router'
import detailHeader from '@/components/home/notice/DetailHeader.vue'
import { showToast } from 'vant'
import axios from 'axios'
import setStorage from '@/store/storage.js'
import common from '@/util/common.js'
import api from '@/util/requestApi.js'
export default {
  components:{
        detailHeader
    },
  setup(){ 
    const router = useRouter()// router === this.$router

    const obj = reactive({
      title:"我的",//标题
      lefttext:"登录",//左边按钮文字
      righttext:"注册",//右边按钮文字
      leftdisabled:false,//左边按钮禁用
      rightdisabled:false,//右边按钮禁用
      text:'',
      fileList:"",
      imgur:"",
      listData:[

      // {
      //   title:"免责声明",
      //   icon:"description-o",
      //   isLink:"",
      //   url:"/my/disclaimers"
      // },{
      //   title:"关于我们",
      //   icon:"info-o",
      //   isLink:"",
      //   url:"/my/about"
      // }
      // ,{
      //   title:"设置",
      //   icon:"setting-o",
      //   isLink:"",
      //   url:"/my/setting"
      // }
      ]
    })

    onMounted(()=>{
      //隐藏左边按钮箭头
      document.querySelector(".van-nav-bar .van-icon").style="display: none;"
   
      var flag = common.isStringNotEmpty(setStorage.getItem('token'));
      if(flag){
          obj.lefttext="";//左边按钮文字
          obj.righttext="";//右边按钮文字
          obj.leftdisabled=true;//左边按钮禁用
          obj.rightdisabled=true;//右边按钮禁用
          document.querySelector(".van-nav-bar__content").style="background-color: rgb(176 171 217);"
          var tokenUser = setStorage.getItem('username');
          var filePath = setStorage.getItem('filePath');
          obj.text = tokenUser
          obj.imgur = filePath
      }else{
        
        document.querySelector(".listmenu").style="margin-top: 46px;"
        document.querySelector(".uploaderfile").style="display: none;"
      }

      console.log(obj.listData)
      init()
    })

    onUnmounted(()=>{
    
    })

    const init = ()=>{ 

      var flag = common.isStringNotEmpty(setStorage.getItem('token'));
      if(flag){
        var token = 'wx '+setStorage.getItem('token');//获取token
        console.log(token)
          //发送请求
          axios({
                method: "get",
                url: api.dev+`/citylife/menu/queryLoadMyList`,
                data: {
                    // phone:setphone,
                    // password:getpassword
                },
                headers: {
                    // 'Content-Type': 'multipart/form-data',
                    'Authorization': token
                }
          }).then((res) => {
              obj.listData = res.data.data[0].children
              console.log(res.data.data[0].children)
              // showToast(res.data.message); 
          }).catch((err) => {
                console.log(err);
          });

      }else{
        //发送请求
        axios({
              method: "get",
              url: api.dev+`/citylife/newContent/activity/queryMenuListmy`,
              data: {
                  // phone:setphone,
                  // password:getpassword 
              },
              headers: {
                'Content-Type': 'application/json;charset=UTF-8'
              }
        }).then((res) => {
          console.log(res)
          obj.listData = res.data.data[0].children
            // showToast(res.data.message); 
        }).catch((err) => {
              console.log(err);
        });
      }
      
    }
    

    const handleClick = (id)=>{
      router.push(`${id}`)
      // window.location.href='https://www.baidu.com/'
    }

    // 登录事件
    const onClickLogin = ()=>{
      router.push(`/my/login`)
    }

    // 注册事件
    const onClickRegister = ()=>{
      router.push(`/my/register`)
    }

    const afterRead = (file)=>{
      console.log(file)
      var fileSize = 1*1024*1024;
      if(file.size>1*fileSize){
        showToast('文件大小不能超过 1 M')
        return
      }
      var token = 'wx '+setStorage.getItem('token');//获取token
      // 需要使用 FormData 进行文件上传
      let form = new FormData();
      form.append("file", file.file);
      console.log(file)
      axios({
          method: "post",
          url: api.dev+`/citylife/ecology/uploadFile`,
          data: form,
          headers: {
              // 'Content-Type': 'application/json;charset=UTF-8',
              'Authorization': token
          }
      })
      .then((res) => {
        console.log(res)
          file.status = "done";
          file.message = "上传成功";
          var filepath = "http://112.74.73.54/"+res.data.message
          obj.imgur = filepath
          setStorage.setItem({value:filepath,name:'filePath',expires:1200000})
      })
      .catch((err) => {
          file.status = "failed";
          file.message = "上传失败";
          console.log(err);
      });
    }
 

    return {
      ...toRefs(obj),
      handleClick,
      onClickLogin,
      onClickRegister,
      afterRead,
      init
      
    }

  }
}
</script>

<style lang="scss" scoped>
.van-cell__left-icon{
  font-size: 30px!important
}
.van-nav-bar .van-icon{
  display: none;
}

.flex {
  // display: flex;
  width: 100%;
}
/*
.topInfo {
  align-items: center;
  background-color: #fff;
  // border-radius: 24px;
}
.arrart {
  width: 100px;
  height: 100px;
  border-radius: 50%;
}
.rightVip {
  width: 552px;
  align-items: center;
}

.sendInfo{
  margin-top: 46px;
  align-items: center;
  text-align: center;
  justify-content: center;
  
}
*/
.uploaderimg{
  
  // display: flex;
  
}
.van-uploader__wrapper{
  
}
.uploaderfile .imgfile{
  background-color: #e2d7d7;
  justify-items: center;
  align-items: center;
  text-align: center;
  img{
    width: 100px;
    height: 100px;
    border-radius: 50%;
    border: 1px solid black;
    margin: auto;
  }
}

  
  
$color:46px;
.uploaderfile{
  margin-top: $color;
  // display: none;
}
.listmenu{
  margin-top: '';
}
.username{
  overflow: hidden;
  white-space: nowrap;//内容超出不换行
  text-overflow: ellipsis;
  font-family: "Arial", sans-serif;

}
</style>