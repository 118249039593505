<template>
    <div>
        <van-cell-group inset>
            <!-- 输入手机号，调起手机号键盘 -->
            <van-field  type="tel" v-model="phone" label="手机号" placeholder="请输入手机号" />
            <!-- 输入密码 -->
            <van-field  type="password" v-model="password" label="密码" placeholder="请输入密码"/>
        </van-cell-group>
        <van-button type="primary" :disabled="disabledFlag" @click="submit" size="large">确定</van-button>
    </div>
</template>
<script>
import { onMounted,inject, onUnmounted, reactive, toRefs,ref } from 'vue'
import {useRoute,useRouter} from 'vue-router'
import { showToast,CountDown} from 'vant';
import http from '@/util/http'
import common from '@/util/common'
import axios from 'axios'
import setStorage from '@/store/storage.js'
import api from '@/util/requestApi.js'
export default {
    setup(props) {
        const router = useRouter()// router === this.$router 
        const isShow = inject("taozishow")//注入 
        const obj = reactive({
            disabledFlag:false,
            phone:"",
            password:""
        })

        const submit = ()=>{

            obj.disabledFlag = true;
            //判断是否为空 
            var setphone = common.trimAll(obj.phone);
            var phone = common.isStringNotEmpty(setphone)
            if(!phone){
                showToast('请输入手机号');
                return
            }
            var getpassword = common.trimAll(obj.password);
            var password = common.isStringNotEmpty(getpassword)
            if(!password){
                showToast('请输入密码');
                return
            }
            //发送请求
            axios({
                method: "post",
                url: api.dev+`/citylife/newContent/activity/passwordlogin`,
                data: {
                    phone:setphone,
                    password:getpassword
                },
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            })
            .then((res) => {
                obj.disabledFlag = false;
                if(res.data.code==20000){
                    showToast('登录成功');
                    obj.phone="";
                    obj.password="";
                    // localStorage.setItem("token",res.data.data.token)
                    //存储token  过期时间是毫秒   我这里是一周
                    if(res.data.data.user!=null){
                        setStorage.setItem({value:res.data.data.user.filePath,name:'filePath',expires:1200000})
                        setStorage.setItem({value:res.data.data.user.username,name:'username',expires:1200000})
                    }
                    
                    setStorage.setItem({value:res.data.data.token,name:'token',expires:1200000})
                    setTimeout(function() {
                        // 需要执行的代码		
                        //跳转登录页面
                        router.push(`/my`)
                    }, 2500); // 定时时间
                }else if(res.data.code==20001){
                    showToast(res.data.message);
                }
                
            })
            .catch((err) => {
                console.log(err);
            });
        }


        return {
            ...toRefs(obj),
            submit
        }
    }
}
</script>