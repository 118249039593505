<template>
    <div>
        <!-- <detail-header  
            v-scroll="0"
            :title="title"
            :lefttext="lefttext" 
            :returnPath="returnPath"
            @event="change(returnPath)">
        </detail-header> -->
        <van-nav-bar title="免责声明" 
            left-arrow
            left-text="返回"
            @click-left="onClickLeft"/>
        
        <!-- <van-uploader accept="*" :v-model="fileList" :after-read="afterRead">
            </van-uploader> -->

  

        <div class="box">
            最终解释权归该平台所有 
        </div>
    </div>
</template>
<script>
import axios from 'axios'
import { onMounted,inject, onUnmounted, reactive, toRefs,ref } from 'vue'
import {useRoute,useRouter} from 'vue-router'
import detailHeader from '@/components/home/notice/DetailHeader.vue'
export default {
    components:{
        detailHeader
    },
    setup(props) {
        const router = useRouter()// router === this.$router
        const isShow = inject("taozishow")//注入 

        const obj = reactive({
            title:"",//标题
            lefttext:"返回",//左边按钮文字
            fileList:""
        })

        onMounted(()=>{
            isShow.value = false
        })
        onUnmounted(()=>{
            isShow.value = true
        })

        const onClickLeft = ()=>{
            router.push(`/my`)
        }

        const afterRead = (file) => {
            file.status = "uploading";
            file.message = "上传中...";
            // 需要使用 FormData 进行文件上传
            let form = new FormData();
            var files = file.objectUrl;

            form.append("file", file.file);
            console.log(file)
            axios({
                method: "post",
                url: "http://localhost:7777/citylife/citylife/newContent/activity/upload",
                data: form,
                // headers: {
                //     'Content-Type': 'multipart/form-data'
                // }
            })
            .then((res) => {
                file.status = "done";
                file.message = "上传成功";
                console.log(res);
            })
            .catch((err) => {
                file.status = "failed";
                file.message = "上传失败";
                console.log(err);
            });
        };

        return {
            onClickLeft,
            ...toRefs(obj),
            afterRead
            
        }
    }
}
</script>
<style lang="scss" scoped>
.box{
    text-align: center;
    margin-top: 30px;
    color: red;
}
</style>