<template>
  <div>

    <!-- start--搜索 -->
    <div>
      <van-search
        v-model="value"
        shape="round"
        :clearable=true
        background="#4fc08d"
        placeholder="请输入搜索关键词"
      />
    </div>
    <!-- end--搜索 -->

    <!-- start--轮播 -->
    <div>
      <van-swipe class="my-swipe" :autoplay="3000" indicator-color="white">
        <van-swipe-item>
          <van-image fit="contain" @click="handleClickCity(88)"  height="260px" src="https://www.yueshunlian.top/picture/666.png"/>
        </van-swipe-item>
        <van-swipe-item>
          <van-image fit="contain" @click="handleClickCity(88)"  height="260px" src="https://www.yueshunlian.top/picture/666.png"/>
        </van-swipe-item>
   
      </van-swipe>
    </div>
    <!-- end--轮播 -->

    <!-- start--列表 -->
    <div :key="carouselList.length">
      <div v-for="data in carouselList" :key="data.filmId">
        <van-card 
          @click="handleClick(data.jumppath)"
          :thumb-link="{createtime}"
          :desc="data.describe"
          :title="data.title"
          :thumb="data.imagepath"
        >
          <template #tags>
            <div style="margin-top: 25px;">
              发布时间:{{ data.createtime }}
            </div>
          </template>
        </van-card>
        <van-back-top />
      </div>
      <div style="height: 50px;"></div>
    </div>
    <!-- end--列表 -->

  </div>
</template>
<script>
import { onBeforeMount, onMounted, reactive, toRefs } from 'vue'
import { ref } from 'vue'
import { showToast } from 'vant'
import {useRouter} from 'vue-router'
import http from '@/util/http'
export default {
  setup(){
    const value = ref('')

    const router = useRouter()// router === this.$router

    const list = [...Array(50).keys()];
    const obj = reactive({
      thumbLink:"",
      carouselList:[]
    })

    const handleClick = (jumppath)=>{
      router.push(`${jumppath}`)
    }

    const handleClickCity = (id)=>{
            // router.push(`/home/notice/detail/nameList/${id}`)
            // router.push(`/program`)
            showToast('该功能暂未开放')
            return
        }

    onMounted(()=>{
      console.log(7777)
      
      http({
          url: '/citylife/newContent/activity/queryCoverList',
          // headers: {
          //     'X-Host': 'mall.film-ticket.film.list'
          // }
          }).then(res => {
            console.log(res.data)
            obj.carouselList = res.data.data
      })
    })
    onBeforeMount(()=>{
      console.log(8888)
    })



    return {
      ...toRefs(obj),
      value,
      handleClick,
      list,
      handleClickCity
    }
  }
}
</script>

<style lang="scss" scoped>
.my-swipe .van-swipe-item {
    color: #fff;
    font-size: 20px;
    line-height: 18.75rem;
    height: 260px;
    text-align: center;
    background-color: #f7f8fa;
  }
</style>
