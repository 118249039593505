<template>
    <div class="header"> 
        <van-nav-bar 
            :title="title"
            :left-text="lefttext" 
            :right-text="righttext"
            left-arrow
            :left-disabled="leftdisabled"
            :right-disabled="rightdisabled"
            @click-right="onClickRegister"
            @click-left="onClickLogin"/>

        <slot></slot>
    </div>
</template>
<script>
export default {
    // 接受属性 
    /**
     * title 标题
     * lefttext 左边按钮文字 
     * righttext 右边按钮文字 
     * leftdisabled 隐藏左边按钮
     */
    props:["title","lefttext","righttext","leftdisabled","rightdisabled"],
    setup(props,{emit}){
        const onClickLogin= ()=> {
            emit("event")
        }
        const onClickRegister= ()=> {
            emit("register")
        }

        return {
            onClickLogin,
            onClickRegister
        }
    }
}
</script>
<style lang="scss" scoped>
.header{
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    // height: 3.125rem;
    // line-height: 3.125rem;
    background: white;
    text-align: center;
}
</style>
