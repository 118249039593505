import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import Vant from 'vant'
import 'vant/lib/index.css'
import VueAwesomeSwiper from 'vue-awesome-swiper';
import 'swiper/css';
// 导入视频播放组件
import VueVideoPlayer from '@videojs-player/vue'
import 'video.js/dist/video-js.css'

createApp(App).use(Vant).use(VueAwesomeSwiper).use(VueVideoPlayer).use(store).use(router).mount('#app')
