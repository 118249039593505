<template>
    <div>
        <detail-header 
            v-scroll="0"
            :title="title"
            :lefttext="lefttext" 
            :returnPath="returnPath"
            @event="change(returnPath)">
        </detail-header>


        <van-tabs :active="active">
        <van-tab title="标签 1">内容 1</van-tab>
        <van-tab title="标签 2">内容 2</van-tab>
        <van-tab title="标签 3">内容 3</van-tab>
        <van-tab title="标签 4">内容 4</van-tab>
        </van-tabs>


        <!-- <van-steps direction="vertical" :active="0" style="margin-top: 46px;">
            <van-step @click="onclicwudao">
                <h3>舞蹈【科目三】【今夜舞起来】</h3>
                <p>柳丽红</p>
            </van-step>
            <van-step>
                <h3>舞蹈【山鬼】</h3>
                <p>李汶洁</p>
            </van-step>
            <van-step>
                <h3>【为青春喝彩】【一次狂个够】</h3>
                <p>信都镇舞动青春队</p>
            </van-step>
            <van-step>
                <h3>歌曲【我是否在你心中】【负心的你】</h3>
                <p>莫伸亮</p>
            </van-step>
            <van-step>
                <h3>【舞蹈串烧】</h3>
                <p>李晓翠</p>
            </van-step>
            <van-step>
                <h3>舞蹈【走着走着花就开了】【古人泪】</h3>
                <p>岑雪珍</p>
            </van-step>
            <van-step>
                <h3>舞蹈【美丽的神话】</h3>
                <p>罗燕杏</p>
            </van-step>
            <van-step>
                <h3>舞蹈【出水连】【豆蔻梢头】</h3>
                <p>陈栩萍</p>
            </van-step>
            <van-step>
                <h3>舞蹈【nxde】【Babbie】</h3>
                <p>...</p>
            </van-step>
            <van-step>
                <h3>歌曲【驼铃】</h3>
                <p>莫家武</p>
            </van-step>
            <van-step>
                <h3>舞蹈【陪你一起闯天下】【跑四回】</h3>
                <p>罗亚雪</p>
            </van-step>
            <van-step>
                <h3>舞蹈&&歌曲【爸爸您辛苦了】【科目三】</h3>
                <p>岑思琳</p>
            </van-step>


            <van-step>
                <h3>歌曲【火火的中国火火的时代】【天美地美中国美】</h3>
                <p>陶清</p>
            </van-step>

            <van-step>
                <h3>歌曲【爱你一万年】【朋友的酒】</h3>
                <p>廖镜连</p>
            </van-step>

            <van-step>
                <h3>歌曲【苏小小】</h3>
                <p>蒙向科</p>
            </van-step>

            <van-step>
                <h3>歌曲【妻子】【马儿呀你慢些走】</h3>
                <p>岑思琳</p>
            </van-step>
            <van-step>
                <h3>歌曲【四个老汉歌唱十二月花】【十赞乡村新面貌】</h3>
                <p>仁义镇三联村渭河世基联英文艺队</p>
            </van-step>
            <van-step>
                <h3>歌曲【泪了】【手心的蔷薇】</h3>
                <p>...</p>
            </van-step>
            <van-step>
                <h3>【开幕式助演】</h3>
                <p>信都镇子汇正杨武术队</p>
            </van-step>
            <van-step>
                <h3>舞蹈【恋彩依】【阿哥阿妹】</h3>
                <p>何家口文艺队</p>
            </van-step>
            <van-step>
                <h3>舞蹈【黄玫瑰】【长长的路慢慢的走】</h3>
                <p>欧桂英</p>
            </van-step>
            <van-step>
                <h3>舞蹈【白羽毛】【约会】</h3>
                <p>...</p>
            </van-step>


            <van-step>
                <h3>舞蹈【幸福山歌】【这山这水那么美】</h3>
                <p>...</p>
            </van-step>
            <van-step>
                <h3>歌曲【赖雪萍】</h3>
                <p>嫂子颂</p>
            </van-step>
            <van-step>
                <h3>舞蹈【永远是朋友】【想问情深有几】</h3>
                <p>陈军连</p>
            </van-step>
            <van-step>
                <h3>舞蹈【科目三】【桃花朵朵开】</h3>
                <p>陶佩茹</p>
            </van-step>
            <van-step>
                <h3>舞蹈【有你在】【日不落】</h3>
                <p>陶明格，陶运喜，陶钰琦，陶明喋</p>
            </van-step>

            <van-step>
                <h3>舞蹈【祝福祖国】【日不落】</h3>
                <p>覃秋月</p>
            </van-step>
            <van-step>
                <h3>歌曲【万疆】【毛主席著作闪金光】</h3>
                <p>岑贤玉，陶洁</p>
            </van-step>
            <van-step>
                <h3>歌曲【三十出头】</h3>
                <p>莫世丽</p>
            </van-step>
            <van-step>
                <h3>舞蹈【走进新时代】</h3>
                <p>信都聲悦健身队</p>
            </van-step>
        </van-steps> -->
        <van-back-top />

    </div>
</template>
<script>
import { onMounted,inject, onUnmounted, reactive, toRefs,ref } from 'vue'
import {useRoute,useRouter} from 'vue-router'
import detailHeader from '@/components/home/notice/DetailHeader.vue'
import axios from 'axios'
import setStorage from '@/store/storage.js'
export default{
    components:{
        detailHeader
    },
    setup(props) {
        const router = useRouter()// router === this.$router 
        const isShow = inject("taozishow")//注入 

        const list = [...Array(50).keys()]; 

        const obj = reactive({
            title:"春节节目名单",//标题
            lefttext:"首页",//左边按钮文字
            active:3
            
            
        })

        onMounted(()=>{
            isShow.value = false
        })
        onUnmounted(()=>{
            isShow.value = true
        })

        const change = ()=>{
            router.push(`/`)
        }

        const onclicwudao = ()=>{
            router.push(`/programPlayback`)
        }

        return {
            ...toRefs(obj),
            change,
            onclicwudao
        }
    }
}
</script>