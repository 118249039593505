
//1.判断字符串非空
function isStringNotEmpty(str) {
    if (str && str.trim() !== '') {
      return true;
    }
    return false;
}

//2.判断数组非空
function isArrayNotEmpty(arr) {
    if (arr && Array.isArray(arr) && arr.length > 0) {
      return true;
    }
    return false;
}

//3.判断对象非空
function isObjectNotEmpty(obj) {
    if (obj && Object.keys(obj).length > 0) {
      return true;
    }
    return false;
}

//4.判断数字非空
function isNumberNotEmpty(num) {
    if (typeof num === 'number' && !isNaN(num)) {
      return true;
    }
    return false;
}

//5.判断布尔值非空
function isBooleanNotEmpty(bool) {
    if (typeof bool === 'boolean') {
      return true;
    }
    return false;
}

// 去除所有空格
const trimAll = (ele)=>{
    if(typeof ele === 'string'){
    return ele.split(/[\t\r\f\n\s]*/g).join('');
        
    }else{
        console.error(`${typeof ele} is not the expected type, but the string type is expected`)
    }

}

export default {
    isStringNotEmpty,
    isArrayNotEmpty,
    isObjectNotEmpty,
    isNumberNotEmpty,
    isBooleanNotEmpty,
    trimAll
}