<template>
    <div>
        <van-nav-bar title="设置" 
            left-arrow
            left-text="返回"
            @click-left="onClickLeft"/>

            <van-button type="primary" @click="onClickExit" size="large">退出登录</van-button>
    </div>
</template>
<script>
import axios from 'axios'
import { onMounted,inject, onUnmounted, reactive, toRefs,ref } from 'vue'
import setStorage from '@/store/storage.js'
import {useRoute,useRouter} from 'vue-router'
import detailHeader from '@/components/home/notice/DetailHeader.vue'
import common from '@/util/common.js'
import { showToast,CountDown} from 'vant';
import { showConfirmDialog } from 'vant';
import api from '@/util/requestApi.js'
export default {
    components:{
        detailHeader
    },
    setup(props) {
        const router = useRouter()// router === this.$router
        const isShow = inject("taozishow")//注入

        const obj = reactive({
            title:"",//标题
            lefttext:"返回",//左边按钮文字
        })
        
        onMounted(()=>{
            isShow.value = false
        })
        onUnmounted(()=>{
            isShow.value = true
        })

        const onClickLeft = ()=>{
            router.push(`/my`)
        }

        const onClickExit = ()=>{
            showConfirmDialog({
                title: '退出登录',
            message:
                '是否要退出登录？',
            })
            .then(() => {
                
                var flag = common.isStringNotEmpty(setStorage.getItem('token'));
                if(flag){
                    var token = 'wx '+setStorage.getItem('token')//获取token
                    axios({
                        url:api.dev+`/citylife/ecology/exit`,
                        method:"post",
                        headers: {
                            // 'Content-Type': 'application/json',
                            'Content-Type': 'multipart/form-data',
                            'Authorization': token
                        },
                    }).then((res)=>{
                        if(res.data.code==20000){
                            // on confirm
                            setStorage.removeItem('token');//获取token
                            setStorage.removeItem('filePath');
                            setStorage.removeItem('username');
                            setTimeout(function() {
                                // 需要执行的代码		
                                //跳转登录页面
                                router.push(`/my`)
                            }, 1500); // 定时时间
                        }else{
                            showToast('系统有误');
                            return
                        }
                    })
                    
                }
            })
            .catch(() => {
                // on cancel
            });
        }

        return {
            onClickLeft,
            ...toRefs(obj),
            onClickExit
        }
    }
    
}
</script>