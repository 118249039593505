<template>
    <div>
        <van-search
            v-model="value"
            :show-action="true"
            action-text=""
            placeholder="请输入搜索关键词"
        >
            <template #label>
                <div @click="onClickButton(id)">返回</div>
            </template>
            </van-search>

            <van-index-bar :index-list="computedList" @select="handleChange">
            <div v-for="data in cityList" :key="data.type">
                <van-index-anchor :index="data.type" />
                <van-cell :title="item.name"
                    v-for="item in data.list"
                    :key="item.cityId" @click="handleClick(item)"/>
                </div>
            </van-index-bar>

            <van-back-top />

    </div>
</template>
<script>
import { onMounted,inject, onUnmounted, reactive, toRefs,ref,computed,watch } from 'vue'
import {useRoute,useRouter} from 'vue-router'
import { showToast } from 'vant'
import http from '@/util/http'
import axios from 'axios'

export default {
    setup(props) {
        
        const isShow = inject("taozishow")//注入 

        const value = ref('')

        const router = useRouter()// router === this.$router
        const route = useRoute() //route === this.$route

        const list = [...Array(50).keys()];
        const obj = reactive({
            id:route.params.id,
            value:'',
            cityList: [],
            cityList2:[],
            newFilterCityList:[]
        })
        onMounted(()=>{
            // console.log(route.params.id)
            isShow.value = false
            // 把取消按钮赋空
            document.querySelector(".van-search__action").innerText=""
            document.querySelector(".van-search__label").style="color:#1989fa"

            http({
                url: '/gateway?k=586380',
                headers: {
                    'X-Host': 'mall.film-ticket.city.list'
                }
                }).then(res => {
                obj.cityList = renderCity(res.data.data.cities)
                obj.cityList2 = obj.cityList
            })
        })

        onUnmounted(()=>{
            isShow.value = true
        })

        watch(()=>obj.value,()=>{
            if(trimStr(obj.value)!=="" && trimStr(obj.value)!==undefined){
                watchNames(obj.value)
            }else{
                watchNames(obj.value)
            }
        })

        const onClickButton = (id)=>{
            // router.push(`/home/notice/detail/${id}`)  
            router.push(`/`)  
        }

        const computedList = computed(()=>{
            return obj.cityList.map(item => item.type)
        })

        const trimStr = (str)=>{
            return str.replace(/(^\s*)|(\s*$)/g,"")
        }
        
        const watchNames = (params)=>{
            var newCityList= []
            var names = []

            var filterNames = []
            var newFilterCityList = []

            obj.cityList=[]
            obj.cityList = obj.cityList2

            for(let i=0;i<obj.cityList.length;i++){
                for(let j=0;j<obj.cityList[i].list.length;j++){
                    newCityList.push(obj.cityList[i].list[j])
                    names.push(obj.cityList[i].list[j].name)
                    
                }
            }

            for(let k=0;k<names.length;k++){
                filterNames = names.filter(item=>item.includes(params))
            }
            
            for(let n=0;n<filterNames.length;n++){
                
                
                for(let m=0;m<newCityList.length;m++){
                    
                    if(filterNames[n]===newCityList[m].name){
                
                        newFilterCityList.push(newCityList[m])
                    }
                }
            }

            // obj.cityList=[]
            // obj.cityList = obj.cityList2
            obj.cityList = renderCity(newFilterCityList)
            

        }


        const renderCity = (list)=> {
            const letterList = []
            const cityList = []
            for (let i = 65; i < 91; i++) {
                // console.log(String.fromCharCode(i))
                letterList.push(String.fromCharCode(i))
            }
            letterList.forEach(letter => {
                var newList = list.filter(item => item.pinyin.substring(0, 1).toUpperCase()  === letter)
                
                newList.length > 0 && cityList.push({
                    type: letter,
                    list: newList
                })
                
            })
            
            return cityList
        }

        const handleChange = (data)=>{
            showToast(data);
        }
        
        return {
            isShow,
            value,
            onClickButton,
            ...toRefs(obj),
            computedList,
            renderCity,
            handleChange,
            trimStr,
            watchNames,
            list
        }
    }
}
</script>
<style lang="scss" scoped>
.van-badge__wrapper van-icon van-icon-arrow-left{
    color: #1989fa;
}
.van-toast--html,.van-toast--text{
    min-width: 30px!important;;
}
</style>