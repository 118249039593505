<template>
    <div>
            <detail-header 
                v-scroll="0"
                :title="title"
                :lefttext="lefttext" 
                :returnPath="returnPath"
                @event="change(returnPath)">
            </detail-header>
            
            <van-watermark content="微生态" />

            <!-- <van-button style="margin-top: 50px;" plain type="primary" @click="handleClick">朴素按钮</van-button> -->


            <div class="box">
                <span>《隐私政策》</span>
                <span>《服务条款》</span>
                <span>《免责声明》</span>
            </div>

    </div>
</template>
<script>
import { onMounted,inject, onUnmounted, reactive, toRefs } from 'vue'
import {useRoute,useRouter} from 'vue-router'
import detailHeader from '@/components/home/notice/DetailHeader.vue'
import axios from 'axios'
import setStorage from '@/store/storage.js'
export default {
    components:{
        detailHeader
    },
    setup(props) {
        const router = useRouter()// router === this.$router 
        const isShow = inject("taozishow")//注入 

        const obj = reactive({
            title:"关于我们",//标题
            lefttext:"返回"//左边按钮文字
        })

        onMounted(()=>{
            isShow.value = false
        })
        onUnmounted(()=>{
            isShow.value = true
        })

        const change = ()=>{
            router.push(`/my`)
        }

        const handleClick = ()=>{
            
            // setStorage.removeItem("token"); 

            // var token = 'wx '+setStorage.getItem('token')//获取token
            // console.log(token)
           
            axios({
                url: `/api/citylife/citylife/newContent/activity/queryActivityAll`,
                data: {
                    // phone:'13657842784',
                    // password:'123456'
                },
                headers: {
                    'Content-Type': 'multipart/form-data'
                },
                method:"get",
                
            }).then((res)=>{
                console.log(res)
            })
        }

        return {
            ...toRefs(obj),
            change,
            handleClick
        }
    }
}
</script>
<style lang="scss" scoped>
.box{
    text-align: center;
    color: red;
    position: absolute;
    bottom: 0;
    width: 100%;
    padding: 20px;
}
</style>