<template>
    <div>
    
        <van-cell-group inset>
         <van-field
            v-model="phone"
            center
            clearable
            label="手机号"
            placeholder="请输入手机号码">
        </van-field>
        <van-field
            v-model="sms"
            center
            clearable
            label="验证码"
            placeholder="请输入短信验证码"
        >
            <template #button>
            <van-button :style="isShowButton" size="small" type="primary" @click="start">发送验证码</van-button>
            <van-count-down
                :style="isShowDown"
                ref="countDown"
                millisecond
                :time="60000"
                :auto-start="false"
                format="ss 秒"
                @finish="onFinish"
            />
            </template>
        </van-field>
        </van-cell-group>
        <van-button type="primary" :disabled="disabledFlag" size="large" @click="submit">确定</van-button>

    </div>
</template>
<script>
import { onMounted,inject, onUnmounted, reactive, toRefs,ref } from 'vue'
import {useRoute,useRouter} from 'vue-router'
import { showToast,CountDown} from 'vant';
import http from '@/util/http'
import setStorage from '@/store/storage.js'
import common from '@/util/common'
import axios from 'axios'
import api from '@/util/requestApi.js'
export default {
    setup(props) {
        const router = useRouter()// router === this.$router 
        const countDown = ref(null);
        const obj = reactive({
            disabledFlag:false,
            phone:"",
            sms:"",
            isShowButton:"",
            isShowDown:"display:none"
        })

        // 点击发送
        const start = () => {
            
            //判断是否为空
            var trimAllPhone = common.trimAll(obj.phone);
            var phone =common.isStringNotEmpty(trimAllPhone);
            if(phone){
                obj.isShowDown=""
                obj.isShowButton="display:none"
                countDown.value.start();
                showToast('该功能暂未开放');
                return

                //发送请求
                // axios({
                //     method: "post",
                //     url: api.dev+`/citylife/newContent/activity/sendSMS/${trimAllPhone}`,
                //     headers: {
                //         'Content-Type': 'multipart/form-data'
                //     }
                // })
                // .then((res) => {
                //     if(res.data.code==20000){
                //         showToast("验证码为:"+res.data.data.code);
                //     }else if(res.data.code==20001){
                //         showToast(res.data.message);
                //     }
                    
                // })
                // .catch((err) => {
                //     console.log(err);
                // });

            }else{
                //提示
                showToast('请输入手机号');
            }
        };

        const submit = ()=>{
            obj.disabledFlag = true;
            //判断是否为空
            var trimPhone = common.trimAll(obj.phone);
            var phone = common.isStringNotEmpty(trimPhone)
            if(!phone){
                showToast('请输入手机号');
                return
            }
            var trimsms = common.trimAll(obj.sms);
            var sms = common.isStringNotEmpty(trimsms)
            if(!sms){
                showToast('请输入验证码');
                return
            }
            
            //发送请求
            axios({
                method: "post",
                url: api.dev+`/citylife/newContent/activity/login`,
                data:{
                    phone:trimPhone,
                    code:trimsms
                },
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
                })
                .then((res) => {
                    obj.disabledFlag = false;
                    if(res.data.code==20000){
                        showToast("登录成功");
                        obj.phone=""
                        boj.sms=""
                        //存储token  过期时间是毫秒   我这里是一周
                        if(res.data.data.user!=null){
                            setStorage.setItem({value:res.data.data.user.filePath,name:'filePath',expires:1200000})
                            setStorage.setItem({value:res.data.data.user.username,name:'username',expires:1200000})
                        }
                        setStorage.setItem({value:res.data.data.token,name:'token',expires:1200000})
                        setTimeout(function() {
                            // 需要执行的代码		
                            //跳转登录页面
                            router.push(`/my`)
                        }, 2500); // 定时时间
                    }else if(res.data.code==20001){
                        showToast(res.data.message);
                    }
                    
                })
                .catch((err) => {
                    console.log(err);
                });
        }

        //结束了促发这个函数
        const onFinish = () =>{
            obj.isShowButton=""
            obj.isShowDown="display:none"
            countDown.value.reset();
        };

        
    
        return {
            ...toRefs(obj),
            start,
            countDown,
            onFinish,
            submit
        }
    }
}
</script>